import {
  RISK_SECTION_HIGH,
  RISK_SECTION_LOW,
  RISK_SECTION_MEDIUM,
  RISK_SECTION_VERY_HIGH,
  RISK_SECTION_VERY_LOW,
} from '@consts/risk';
import {
  formatResult,
  formatRisk,
  formatYesNoQuestion,
} from '@/src/common/helpers/quiz';

export const riskSettings = [
  formatRisk(RISK_SECTION_VERY_LOW, 'common.very_low', 'risk.very_low', 0, 0),
  formatRisk(RISK_SECTION_LOW, 'common.low', 'risk.low', 1, 2),
  formatRisk(RISK_SECTION_MEDIUM, 'common.medium', 'risk.medium', 3, 5),
  formatRisk(RISK_SECTION_HIGH, 'common.high', 'risk.high', 6, 9),
  formatRisk(RISK_SECTION_VERY_HIGH, 'common.very_high', 'risk.very_high', 10, 99999),
];

export const resultSettings = [
  formatResult(
    'Det verkar inte som att du löper risk att utveckla långvariga besvär. Om behovet uppstår kan du kontakta en fysioterapeut via Joint Academy.',
    '',
    0,
    0,
  ),
  formatResult(
    'Även mindre axelbesvär kan vara tufft och det går att undvika långvariga besvär med rätt behandling i god tid. Hos Joint Academy kan du prata med en fysioterapeut och få behandling vid behov.',
    'Enligt dina testsvar verkar du ha lättare besvär av din axelsmärta och din risk för att utveckla långvariga problem bedöms som låg. Däremot är det alltid bra att genoföra en utredning i god tid, och få hjälp för att undvika att besvären förvärras eller kommer tillbaka. Med rätt behandling kan du också påskynda din återhämtning. Ett nästa steg för dig kan vara att söka vård för att få en bedömning.<br/><br/>Idag rekommenderas fysioterapi med anpassade övningar och utbildning i första hand som behandling för axelsmärta. Forskning visar att anpassad rehab ger bra resultat och smärtlinding över tid.<br/><br/>Joint Academy erbjuder evidensbaserad behandling direkt i din mobil. Med ett individuellt anpassat program bestående av enkla övningar som minskar smärta och ökar rörlighet. Under behandlingen har du kontakt med en personlig fysioterapeut. Ditt behandlingsprogram utformas med målet att minska dina besvär och ge dig fortsatta förutsättningar för en aktiv, smärtfri vardag. Via Joint Academy kan du också fortsätta din behandling via egenvård.',
    1,
    2,
  ),
  formatResult(
    'Envis smärta i axeln kan vara tufft men det går att minska besvären med rätt behandling i god tid. Hos Joint Academy kan du prata med en fysioterapeut och få behandling vid behov.',
    'Baserat på dina testsvar verkar du besväras av din axelsmärta i vardagen och risken för att utveckla långvariga besvär bedöms som måttlig. Det kan vara bra för dig att genoföra en bedömning av legitimerad vårdpersonal, och få hjälp för att undvika att besvären förvärras. Med rätt behandling kan du också påskynda din återhämtning.<br/><br/>Idag rekommenderas fysioterapi med anpassade övningar och utbildning i första hand som behandling för axelsmärta. Forskning visar att anpassad rehab ger bra resultat och smärtlinding över tid.<br/><br/>Joint Academy erbjuder evidensbaserad behandling direkt i din mobil. Med ett individuellt anpassat program bestående av enkla övningar som minskar smärta och ökar rörlighet. Under behandlingen har du kontakt med en personlig fysioterapeut. Ditt behandlingsprogram utformas med målet att minska dina besvär och ge dig fortsatta förutsättningar för en aktiv, smärtfri vardag. Via Joint Academy kan du också fortsätta din behandling via egenvård.',
    3,
    5,
  ),
  formatResult(
    'Avsevärd smärta i axeln kan vara tufft men det går att minska besvären med rätt behandling i god tid. Hos Joint Academy kan du prata med en fysioterapeut och få behandling vid behov.',
    'Baserat på dina testsvar verkar du ha mycket besvär av din axelsmärta och risken för att utveckla långvariga besvär bedöms som hög. Det kan vara bra för dig att genoföra en bedömning av legitimerad vårdpersonal, och få hjälp för att undvika att besvären förvärras. Med rätt behandling kan du också påskynda din återhämtning.<br/><br/>Idag rekommenderas fysioterapi med anpassade övningar och utbildning i första hand som behandling för axelsmärta. Forskning visar att anpassad rehab ger bra resultat och smärtlinding över tid.<br/><br/>Joint Academy erbjuder evidensbaserad behandling direkt i din mobil. Med ett individuellt anpassat program bestående av enkla övningar som minskar smärta och ökar rörlighet. Under behandlingen har du kontakt med en personlig fysioterapeut. Ditt behandlingsprogram utformas med målet att minska dina besvär och ge dig fortsatta förutsättningar för en aktiv, smärtfri vardag. Via Joint Academy kan du också fortsätta din behandling via egenvård.',
    6,
    99999,
  ),
];

export const questions = [
  formatYesNoQuestion(
    'question1',
    'Har din smärta i axeln påverkat det dagliga livet hemma och/eller på jobbet de senaste två veckorna?',
  ),
  formatYesNoQuestion(
    'question2',
    'Har din smärta i axeln hindrat dig från att sova de senaste två veckorna?',
  ),
  formatYesNoQuestion(
    'question3',
    'Har du känt mindre intresse eller glädje i att göra saker de senaste två veckorna?',
  ),
  formatYesNoQuestion(
    'question4',
    'Har du känt dig nedstämd, deprimerad eller känt att framtiden ser hopplös ut de senaste två veckorna?',
  ),
  formatYesNoQuestion(
    'question5',
    'Har din smärta i axeln förhindrat dig från att leva ett normalt liv de senaste två veckorna?',
  ),
  formatYesNoQuestion(
    'question6',
    'Har din smärta i axeln hindrat dig från att umgås med vänner och familj lika mycket som vanligt de senaste två veckorna?',
  ),
  formatYesNoQuestion(
    'question7',
    'Har du haft dina besvär i åtminstone 2-3 veckor utan någon minskning av smärtan i axeln?',
  ),
  formatYesNoQuestion(
    'question8',
    'Tror du att det kan vara skadligt för en person med dina besvär att vara fysiskt aktiv?',
  ),
  formatYesNoQuestion(
    'question9',
    'Tror du att dina besvär kommer att vara långvariga?',
  ),
];

export const references = [
  'Wertli MM, Rasmussen-Barr E, Weiser S, Bachmann LM, Brunner F. The role of fear avoidance beliefs as a prognostic factor for outcome in patients with nonspecific low back pain: a systematic review [published correction appears in Spine J. Aug 1;14(8):a18]. Spine J. 2014;14(5):816-36.e4. doi:10.1016/j.spinee.2013.09.036',
  'Cohen SP, Vase L, Hooten WM. Chronic pain: an update on burden, best practices, and new advances. Lancet. 2021;397(10289):2082-2097. doi:10.1016/S0140-6736(21)00393-7',
  'Luque-Suarez A, Martinez-Calderon J, Falla D. Role of kinesiophobia on pain, disability and quality of life in people suffering from chronic musculoskeletal pain: a systematic review. Br J Sports Med. 2019;53(9):554-559. doi:10.1136/bjsports-2017-098673',
  'Burgess R, Mansell G, Bishop A, Lewis M, Hill J. Predictors of functional outcome in musculoskeletal healthcare: An umbrella review. Eur J Pain. 2020;24(1):51-70. doi:10.1002/ejp.1483',
  'Vlaeyen JWS, Linton SJ. Fear-avoidance and its consequences in chronic musculoskeletal pain: a state of the art. Pain. 2000;85(3):317-332. doi:10.1016/S0304-3959(99)00242-0',
];
